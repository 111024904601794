<template>
    <div id="main-container" class="main-container">
        <div id="ic-wrapper">
            <div id="main-title" class="main-title">
                <h1 class="typo-headline-6">
                    Calculadoras de inflación
                </h1>
                <p class="typo-body-2">
                    Calculan la variación de precios provocados por la inflación.
                </p>
            </div>
            <div>
                <Tab :tab_options="tab_obj" @_toggle_tab="_getIndexTab" />
            </div>
            <div v-if="tab_indexId == 'annual_cpi'" class="chart-container">
                <div id="spainCPI_values" class="spainCPI_values"></div>
            </div>
            <div v-if="tab_indexId == 'monthly_cpi'" class="monthly-chart-container">
                <div id="MonthlyCPI_values" class="monthlyCPI_values"></div>
            </div>
            <div v-if="this.show_popup" id="popup-container" class="popup-style-container">
                <div id="popup-modal" class="popup-modal">
                    <div class="logo-eurekers">
                        <img src="../assets/img/eurekers.jpg" title="logo-eurekers" width=50 height=50/ />
                    </div>
                    <div id="popup-form-container" class="popup-form-container">
                        <div id="text-container" class="text-container">
                            <p>
                                En Eurekers tenemos herramientas como esta, que te ayudarán a gestionar tus inversiones. Si quieres, déjanos tu teléfono e email para que te podamos enviar más información.
                            </p>
                        </div>
                        <div id="popup_tlf-container" class="popup_tlf-container">
                            <span>
                                <b>Teléfono</b>
                            </span>
                            <div>
                                <InputTextOutline @_emit_method_order_input="_getUserTlf" :input_label="input_popup[0].input_label" :input_helper="input_popup[0].input_helper" :has_focus="input_popup[0].has_focus" :icon_class="input_popup[0].icon_class" :input_key_to_save_user_values="input_popup[0].input_key_to_save_user_values" :input_class_character_counter="input_popup[0].input_class_character_counter" :permission="input_popup[0].permission" :icon_permission="input_popup[0].icon_permission" :character_counter_permission="input_popup[0].character_counter_permission" :helper_permission="input_popup[0].helper_permission" :test_field_is_numeric="input_popup[0].test_field_is_numeric" :length="input_popup[0].length" :is_input_textarea="input_popup[0].is_input_textarea" class="input-style-tlf" />
                            </div>
                        </div>
                        <div id="popup_email-container" class="popup_email-container">
                            <span>
                                <b>Email</b>
                            </span>
                            <div>
                                <InputTextOutline @_emit_method_order_input="_getUserEmail" :input_label="input_popup[1].input_label" :input_helper="input_popup[1].input_helper" :has_focus="input_popup[1].has_focus" :icon_class="input_popup[1].icon_class" :input_key_to_save_user_values="input_popup[1].input_key_to_save_user_values" :input_class_character_counter="input_popup[1].input_class_character_counter" :permission="input_popup[1].permission" :icon_permission="input_popup[1].icon_permission" :character_counter_permission="input_popup[1].character_counter_permission" :helper_permission="input_popup[1].helper_permission" :test_field_is_numeric="input_popup[1].test_field_is_numeric" :length="input_popup[1].length" :is_input_textarea="input_popup[1].is_input_textarea" class="input-style-email" />
                            </div>
                            <div class="btn-send-container">
                                <button class="btn-send" @click="_sendInfo()"> ENVIAR </button>
                            </div>
                        </div>
                    </div>
                    <div class="btn-close-container">
                        <button class="material-icons-outlined btn-close" @click="closePopup()">close</button>
                    </div>
                </div>
            </div>
            <div id="spain-container" class="spain-container">
                <div id="spainInflation-container" class="spainInflation-container">
                    <div id="spainInflation-title" class="spainInflation-title">
                        <h2>
                            Calculadora de inflación en España (desde 1999 hasta 2023)
                        </h2>
                        <p>
                            Gracias a los datos históricos recogidos por esta herramienta, calcula la variación de precios en España.
                        </p>
                    </div>
                    <div id="spainInflation-calc" class="spainInflation-calc">
                        <div id="first-row-container" class="first-row-container">
                            <div id="input-euro-container" class="input-euro-container">
                                <InputTextOutline @_emit_method_order_input="_getValueFromEuroFirstRow" :input_label="input_inflation_calculator[8].input_label" :input_helper="input_inflation_calculator[8].input_helper" :has_focus="input_inflation_calculator[8].has_focus" :icon_class="input_inflation_calculator[8].icon_class" :input_key_to_save_user_values="input_inflation_calculator[8].input_key_to_save_user_values" :input_class_character_counter="input_inflation_calculator[8].input_class_character_counter" :permission="input_inflation_calculator[8].permission" :icon_permission="input_inflation_calculator[8].icon_permission" :character_counter_permission="input_inflation_calculator[8].character_counter_permission" :helper_permission="input_inflation_calculator[8].helper_permission" :test_field_is_numeric="input_inflation_calculator[8].test_field_is_numeric" :length="input_inflation_calculator[8].length" :is_input_textarea="input_inflation_calculator[8].is_input_textarea" class="input-style" />
                            </div>
                            <div id="input-year-container" class="input-year-container">
                                <InputTextOutline @_emit_method_order_input="_getValueFromYearFirstRow" :input_label="input_inflation_calculator[9].input_label" :input_helper="input_inflation_calculator[9].input_helper" :has_focus="input_inflation_calculator[9].has_focus" :icon_class="input_inflation_calculator[9].icon_class" :input_key_to_save_user_values="input_inflation_calculator[9].input_key_to_save_user_values" :input_class_character_counter="input_inflation_calculator[9].input_class_character_counter" :permission="input_inflation_calculator[9].permission" :icon_permission="input_inflation_calculator[9].icon_permission" :character_counter_permission="input_inflation_calculator[9].character_counter_permission" :helper_permission="input_inflation_calculator[9].helper_permission" :test_field_is_numeric="input_inflation_calculator[9].test_field_is_numeric" :length="input_inflation_calculator[9].length" :is_input_textarea="input_inflation_calculator[9].is_input_textarea" class="input-style" />
                            </div>
                        </div>
                        <div id="third-row-container" class="third-row-container">
                            <div id="input-year-container" class="input-year-container">
                                <InputTextOutline @_emit_method_order_input="_getValueFromYearSecondRow" :input_label="input_inflation_calculator[11].input_label" :input_helper="input_inflation_calculator[11].input_helper" :has_focus="input_inflation_calculator[11].has_focus" :icon_class="input_inflation_calculator[11].icon_class" :input_key_to_save_user_values="input_inflation_calculator[11].input_key_to_save_user_values" :input_class_character_counter="input_inflation_calculator[11].input_class_character_counter" :permission="input_inflation_calculator[11].permission" :icon_permission="input_inflation_calculator[11].icon_permission" :character_counter_permission="input_inflation_calculator[11].character_counter_permission" :helper_permission="input_inflation_calculator[11].helper_permission" :test_field_is_numeric="input_inflation_calculator[11].test_field_is_numeric" :length="input_inflation_calculator[11].length" :is_input_textarea="input_inflation_calculator[11].is_input_textarea" class="input-style" />
                            </div>
                        </div>
                        <div id="btn-container">
                            <button @click="_spainInflation()" class="btn-calc-spain"> CALCULAR </button>
                        </div>
                        <div v-if="this.res_container" id="res-container" class="res-container">
                            <p>
                                <br>
                                <b class="res-value">{{this.euro_second_row}}€</b>
                                <br>
                                El poder adquisitivo de {{this.euro_first_row_calculated}}€ en {{this.year_first_row_calculated}} es igual al de {{this.euro_second_row}}€ en {{this.year_second_row_calculated}}
                            </p>
                        </div>
                        <div class="chart-container">
                            <div id="highChart-content" class="highChart-content"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="futureInflation-container" class="futureInflation-container">
                <div id="futureInflation-title" class="futureInflation-title">
                    <h4>
                        <b>Cálculo de valor futuro con inflación estimada</b>
                    </h4>
                    <p>
                        Calcula el cambio de valor si hubiera en el futuro una variación en la inflación
                    </p>
                </div>
                <div id="futureInflation-calc" class="futureInflation-calc">
                    <div id="inputs-container" class="inputs-container">
                        <div id="input-values-container" class="input-values-container">
                            <div class="input-1">
                                <InputTextOutline @_emit_method_order_input="_getValueFromFutureQuantity" :input_label="input_inflation_calculator[1].input_label" :input_helper="input_inflation_calculator[1].input_helper" :has_focus="input_inflation_calculator[1].has_focus" :icon_class="input_inflation_calculator[1].icon_class" :input_key_to_save_user_values="input_inflation_calculator[1].input_key_to_save_user_values" :input_class_character_counter="input_inflation_calculator[1].input_class_character_counter" :permission="input_inflation_calculator[1].permission" :icon_permission="input_inflation_calculator[1].icon_permission" :character_counter_permission="input_inflation_calculator[1].character_counter_permission" :helper_permission="input_inflation_calculator[1].helper_permission" :test_field_is_numeric="input_inflation_calculator[1].test_field_is_numeric" :length="input_inflation_calculator[1].length" :is_input_textarea="input_inflation_calculator[1].is_input_textarea" class="input-style-Quantity" />
                            </div>
                            <div class="input-2">
                                <InputTextOutline @_emit_method_order_input="_getValueFromFutureAverageInflation" :input_label="input_inflation_calculator[0].input_label" :input_helper="input_inflation_calculator[0].input_helper" :has_focus="input_inflation_calculator[0].has_focus" :icon_class="input_inflation_calculator[0].icon_class" :input_key_to_save_user_values="input_inflation_calculator[0].input_key_to_save_user_values" :input_class_character_counter="input_inflation_calculator[0].input_class_character_counter" :permission="input_inflation_calculator[0].permission" :icon_permission="input_inflation_calculator[0].icon_permission" :character_counter_permission="input_inflation_calculator[0].character_counter_permission" :helper_permission="input_inflation_calculator[0].helper_permission" :test_field_is_numeric="input_inflation_calculator[0].test_field_is_numeric" :length="input_inflation_calculator[0].length" :is_input_textarea="input_inflation_calculator[0].is_input_textarea" class="input-style" />
                            </div>
                            <div class="input-3">
                                <InputTextOutline @_emit_method_order_input="_getValueFromFuturePeriod" :input_label="input_inflation_calculator[2].input_label" :input_helper="input_inflation_calculator[2].input_helper" :has_focus="input_inflation_calculator[2].has_focus" :icon_class="input_inflation_calculator[2].icon_class" :input_key_to_save_user_values="input_inflation_calculator[2].input_key_to_save_user_values" :input_class_character_counter="input_inflation_calculator[2].input_class_character_counter" :permission="input_inflation_calculator[2].permission" :icon_permission="input_inflation_calculator[2].icon_permission" :character_counter_permission="input_inflation_calculator[2].character_counter_permission" :helper_permission="input_inflation_calculator[2].helper_permission" :test_field_is_numeric="input_inflation_calculator[2].test_field_is_numeric" :length="input_inflation_calculator[2].length" :is_input_textarea="input_inflation_calculator[2].is_input_textarea" class="input-style-Period" />
                            </div>
                        </div>
                        <div v-if="this.future_value" id="future_res" class="future_res">
                            <p>
                                <b class="res_future_value">{{this.futureRes}}</b>
                                <br>
                                Con una inflación del {{this.futureAverageInflation_calculated}}%, el poder adquisitivo de {{this.futureQuantity_calculated}}€ es igual a
                                <br> 
                                {{this.futureRes}} en {{this.futurePeriod_calculated}} años.
                            </p>
                        </div>
                    </div>
                    <div id="btn-container">
                        <button @click="_futureInflation()" class="btn-calc-future"> CALCULAR </button>
                    </div>
                </div>
            </div>
            <div id="pastInflation-container" class="pastInflation-container">
                <div id="pastInflation-title" class="pastInflation-title">
                    <h4>
                        <b>Cálculo de valor pasado con inflación estimada</b>
                    </h4>
                    <p>
                        Calcula el cambio de valor si hubiera habido en el pasado una variación en la inflación
                    </p>
                </div>
                <div id="pastInflation-calc" class="pastInflation-calc">
                    <div id="inputs-container" class="inputs-container">
                        <div id="input-values-container" class="input-values-container">
                            <div class="input-1">
                                <InputTextOutline @_emit_method_order_input="_getValueFromPastQuantity" :input_label="input_inflation_calculator[5].input_label" :input_helper="input_inflation_calculator[5].input_helper" :has_focus="input_inflation_calculator[5].has_focus" :icon_class="input_inflation_calculator[5].icon_class" :input_key_to_save_user_values="input_inflation_calculator[5].input_key_to_save_user_values" :input_class_character_counter="input_inflation_calculator[5].input_class_character_counter" :permission="input_inflation_calculator[5].permission" :icon_permission="input_inflation_calculator[5].icon_permission" :character_counter_permission="input_inflation_calculator[5].character_counter_permission" :helper_permission="input_inflation_calculator[5].helper_permission" :test_field_is_numeric="input_inflation_calculator[5].test_field_is_numeric" :length="input_inflation_calculator[5].length" :is_input_textarea="input_inflation_calculator[5].is_input_textarea" class="input-style-Quantity" />
                            </div>
                            <div class="input-2">
                                <InputTextOutline @_emit_method_order_input="_getValueFromPastAverageInflation" :input_label="input_inflation_calculator[4].input_label" :input_helper="input_inflation_calculator[4].input_helper" :has_focus="input_inflation_calculator[4].has_focus" :icon_class="input_inflation_calculator[4].icon_class" :input_key_to_save_user_values="input_inflation_calculator[4].input_key_to_save_user_values" :input_class_character_counter="input_inflation_calculator[4].input_class_character_counter" :permission="input_inflation_calculator[4].permission" :icon_permission="input_inflation_calculator[4].icon_permission" :character_counter_permission="input_inflation_calculator[4].character_counter_permission" :helper_permission="input_inflation_calculator[4].helper_permission" :test_field_is_numeric="input_inflation_calculator[4].test_field_is_numeric" :length="input_inflation_calculator[4].length" :is_input_textarea="input_inflation_calculator[4].is_input_textarea" class="input-style" />
                            </div>
                            <div class="input-3">
                                <InputTextOutline @_emit_method_order_input="_getValueFromPastPeriod" :input_label="input_inflation_calculator[7].input_label" :input_helper="input_inflation_calculator[7].input_helper" :has_focus="input_inflation_calculator[7].has_focus" :icon_class="input_inflation_calculator[7].icon_class" :input_key_to_save_user_values="input_inflation_calculator[7].input_key_to_save_user_values" :input_class_character_counter="input_inflation_calculator[7].input_class_character_counter" :permission="input_inflation_calculator[7].permission" :icon_permission="input_inflation_calculator[7].icon_permission" :character_counter_permission="input_inflation_calculator[7].character_counter_permission" :helper_permission="input_inflation_calculator[7].helper_permission" :test_field_is_numeric="input_inflation_calculator[7].test_field_is_numeric" :length="input_inflation_calculator[7].length" :is_input_textarea="input_inflation_calculator[7].is_input_textarea" class="input-style-Period" />
                            </div>
                        </div>
                        <div v-if="this.past_value" id="past_res" class="past_res">
                            <p>
                                <b class="res_past_value">{{this.pastRes}}</b>
                                <br>
                                Con una inflación del {{this.pastAverageInflation_calculated}}%, el poder adquisitivo de {{this.pastQuantity_calculated}}€ es igual a
                                <br> 
                                {{this.pastRes}} hace {{this.pastPeriod_calculated}} años.
                            </p>
                        </div>
                    </div>
                    <div id="btn-container" style="margin-bottom: 20px;">
                        <button @click="_pastInflation()" class="btn-calc-past"> CALCULAR </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* HighChart */
import Highcharts from "highcharts";

/* Getter */
import { mapGetters } from "vuex";

/* Vue */
import Vue from 'vue';

/* Tab */
import Tab from "@/components/helpers/Tab.vue";

/* Material Icons Outline */
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';

/* API Caller */
import APICaller from "@/components/codeComponents/APICaller.js";

/* JSON */
import inputInflation from './configs/inflation-calculator/input-inflation-calculator.json';
import inputPopup from './configs/popup/input-popup.json';

export default {
    mixins:[APICaller],
    
    name: 'InflationCalculator',

    metaInfo() {
        return {
            title: 'Calculadora de inflación Eurekers',
            meta:[
                { name: 'description', content: "La calculadora de inflación le permite realizar un seguimiento de la variación de los precios en España, ofrecida por Eurekers"}
            ]
        }
    },
        
    components: {
        InputTextOutline,
        Tab
    },

    data() {
        return{
            input_inflation_calculator: inputInflation,
            futureAverageInflation: '',
            futureAverageInflation_calculated: '',
            futureQuantity: '',
            futureQuantity_calculated: '',
            futurePeriod: '',
            futurePeriod_calculated: '',
            futureRes: 'RESULTADO (€)',
            pastAverageInflation: '',
            pastAverageInflation_calculated: '',
            pastQuantity: '',
            pastQuantity_calculated: '',
            pastRes: 'RESULTADO (€)',
            pastPeriod: '',
            pastPeriod_calculated: '',
            euro_first_row: '',
            euro_first_row_calculated: '',
            year_first_row: '',
            year_first_row_calculated: '',
            euro_second_row: 'Cantidad (€)',
            year_second_row: '',
            year_second_row_calculated: '',
            AnnualCPI: [],
            MonthlyCPI: [],
            MonthlyName: [],
            arrayMonthlyName: [],
            arrayMonthlyCPI: [],
            x_categories: [],
            CPIdata: [],
            res_container: false,
            future_value: false,
            past_value: false,
            spain_CPI_rate: [],
            spainCPIValues: [],
            input_popup: inputPopup,
            popup_counter: 0,
            user_tlf: '',
            user_email: '',
            show_popup: false,
            inflationValues: [],
            inflationMonths: [],
            arrayInflationYears: [],

            tab_indexId: "annual_cpi",

            tab_obj: [
                {
                    tab_name: "Inflación Anual",
                    tab_id: "annual_cpi"
                },
                {
                    tab_name: "Inflación mensual",
                    tab_id: "monthly_cpi"
                }
            ],
        }
    },

    mounted(){
        
    },

    computed: {
        ...mapGetters(['is_mobile_device']),
    },

    created(){
        document.getElementsByTagName("html")[0].classList.remove("noscroll");
        this._getInflationValues();
        this._getAnnualCPI();
        this._getMonthlyCPI();
        this._isIframe();       
        
        var spainCPI_highchart = setInterval(() => {
            if(document.getElementById("spainCPI_values") != null || document.getElementById("spainCPI_values") != undefined){
                this._addDataToHighchartSpainCPI();   
                clearInterval(spainCPI_highchart);
            }
        }, 100);
        
        var getdata = setInterval(() => {
            if((this.MonthlyCPI != "" || this.MonthlyCPI != undefined || this.MonthlyCPI != null) && (this.MonthlyName != "" || this.MonthlyName != undefined || this.MonthlyName != null)){
                this._addMonthlyDataToHighCharts();
                clearInterval(getdata);
            }
        }, 250);
    },

    methods: {

        _getIndexTab(tab_id) {
            this.tab_indexId = tab_id;

            this._displayChart();

        },

        _displayChart(){
            if(this.tab_indexId == 'annual_cpi'){
                this._addDataToHighchartSpainCPI();
            } else{
                this._addMonthlyDataToHighCharts();
            }
        },
            
        _getValueFromFutureAverageInflation(value){
            this.futureAverageInflation = value[0];
        },

        _getValueFromFutureQuantity(value){
            this.futureQuantity = value[0];
        },

        _getValueFromFuturePeriod(value){
            this.futurePeriod = value[0];
        },

        _getValueFromFutureRes(value){
            this.futureRes = value[0];
        },

        _getValueFromPastAverageInflation(value){
            this.pastAverageInflation = value[0];
        },

        _getValueFromPastQuantity(value){
            this.pastQuantity = value[0];
        },

        _getValueFromPastRes(value){
            this.pastRes = value[0];
        },
            
        _getValueFromPastPeriod(value){
            this.pastPeriod = value[0];
        },

        _getValueFromEuroFirstRow(value){
            this.euro_first_row = value[0];
        },

        _getValueFromYearFirstRow(value){
            this.year_first_row = value[0];
        },

        _getValueFromEuroSecondRow(value){
            this.euro_second_row = value[0];
        },

        _getValueFromYearSecondRow(value){
            this.year_second_row = value[0];
        },

        _getUserTlf(value){
            this.user_tlf = value[0];
        },

        _getUserEmail(value){
            this.user_email = value[0];
        },

        _sendInfo(){
            
            this.result = true;
            this.result = this.result * this._emailValidator(this.user_email) * this._tlfValidator(this.user_tlf);
            
            if(this.result){
                
                var cookieStorage = window.localStorage;
                cookieStorage.setItem("email", this.user_email);
                cookieStorage.setItem("phone_number", this.user_tlf);

                let data = new URLSearchParams();
                data.append('email', this.user_email);
                data.append('tlf', this.user_tlf);

                this._postInfo(data);

            }
        },

        _postInfo(data){
            let success = response => {
                if(response.status == 200){
                    this.closePopup();
                } else{
                    this.$vueOnToast.pop('error', 'Ha sucedido un error. Por favor, inténtelo de nuevo.');
                }
            };

            let url = "/calculadora/datos"

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('295', 'Error en la calculadora.');

            this._postAPICall(url, data, successHandler, failureHandler);

        },

        _tlfValidator: function(valor){
            if(/^\d{9}$/.test(valor)){
                return true;
            }else{
                this.$vueOnToast.pop('error', 'Numero de telefono incorrecto.');
                return false;
            }
        },

        _emailValidator: function(valor){
            if(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(valor)){
            return true;
            }else{
                this.$vueOnToast.pop('error', 'Email incorrecto.');
                return false;
            } 
        },

        _openPopUp: function(){
            this.show_popup = false;
            var cookieStorage = window.localStorage;
            var em = cookieStorage.getItem("email");
            var tel = cookieStorage.getItem("phone_number");
            if((this.popup_counter == 3 || this.popup_counter == 10) && (em == null || tel == null)){
                this.show_popup = true;
            }
        },

        closePopup(){
            var popup_div = document.getElementById("popup-modal");
            var background = document.getElementById("popup-container");
            popup_div.style.display = "none";
            background.style.display = "none"
        },
            
        _futureInflation(){
            this.futureAverageInflation_calculated = this.futureAverageInflation;
            this.futurePeriod_calculated = this.futurePeriod;
            this.futureQuantity_calculated = this.futureQuantity;
            var r = this.futureAverageInflation;
            var rt = r / 100;
            var vi = this.futureQuantity;
            var t = this.futurePeriod;

            if(r == '' || vi == '' || t == ''){
                this.$vueOnToast.pop('error', 'Por favor, rellene todos los campos.');
            } else{
                if(r < 0 || vi < 0 || t < 0){
                    this.$vueOnToast.pop('error', 'Por favor, introduzca valores positivos.');
                } else{
                    var powed = Math.pow(1+rt,t);
                    var mult = vi * powed;
                    this.futureRes = Math.round((mult + Number.EPSILON) * 100) / 100 + '€';
                    this.future_value = true;
                    this.popup_counter++;
                    this._openPopUp();
                }
            }

            if(this.is_mobile_device == true){
                setTimeout(() => {
                    let future_resDiv = document.getElementById("future_res").getBoundingClientRect();
                    window.scrollTo(future_resDiv.y, future_resDiv.y+300);
                }, 1000);
            }

        },

        _pastInflation(){
            this.pastAverageInflation_calculated = this.pastAverageInflation;
            this.pastQuantity_calculated = this.pastQuantity;
            this.pastPeriod_calculated = this.pastPeriod;
            var r = this.pastAverageInflation;
            var rt = r / 100;
            var vi = this.pastQuantity;
            var t = this.pastPeriod;

            if(r == '' || vi == '' || t == ''){
                this.$vueOnToast.pop('error', 'Por favor, rellene todos los campos.');

            } else{
                if(r < 0 || vi < 0 || t < 0){
                    this.$vueOnToast.pop('error', 'Por favor, introduzca valores positivos.');
                } else{

                    var powed = Math.pow(1-rt,t);
                    var mult = vi * powed;
                    this.pastRes = Math.round((mult + Number.EPSILON) * 100) / 100 + '€';
                    this.past_value = true;
                    this.popup_counter++;
                    this._openPopUp();
                }
            }

            if(this.is_mobile_device == true){
                setTimeout(() => {
                    let rectPast = document.getElementById("pastInflation-container").getBoundingClientRect();
                    window.scrollTo(rectPast.y, document.body.scrollHeight);
                }, 1000);
            }

        },

        _spainInflation(){
            this.euro_first_row_calculated = this.euro_first_row;
            this.year_first_row_calculated = this.year_first_row;
            this.year_second_row_calculated = this.year_second_row;
                
            this.CPIdata = [];
            this.x_categories = [];
            var CPIvalues = this.AnnualCPI;
            var initial_year_CPIvalue = 0;
            var final_year_CPIvalue = 0;    
            var input_initial_year = this.year_first_row;
            
            var input_final_year = this.year_second_row;
            if(input_initial_year == "" || input_final_year == "" || this.euro_first_row == ""){

                this.$vueOnToast.pop('error', 'Por favor, rellene todos los campos.');
            } else if(this.euro_first_row < 0){

                this.$vueOnToast.pop('error', 'Por favor, introduzca valores positivos.');
            } else if((input_initial_year < 1999 || input_initial_year > 2023) || (input_final_year > 2023 || input_final_year < 1999)){

                this.$vueOnToast.pop('error', 'Por favor, introduzca un valor entre 1999 y 2023.');

            } else if(input_final_year < input_initial_year){

                this.$vueOnToast.pop('error', 'Por favor, introduzca correctamente los valores.');

            } else {
                initial_year_CPIvalue = CPIvalues[input_initial_year - 1999][0];
                final_year_CPIvalue = CPIvalues[input_final_year - 1999][0];
    
                var inflationBtwYears = ((final_year_CPIvalue - initial_year_CPIvalue) / initial_year_CPIvalue) * 100;
    
                var aux = (inflationBtwYears / 100) + 1;
    
                this.euro_second_row = Math.round(((this.euro_first_row * aux) + Number.EPSILON) * 100) / 100;
    
                this.x_categories.push(this.year_first_row);
                this._buildChartCategories();
                this.x_categories.push(this.year_second_row);
                
                this.CPIdata.push(parseInt(this.euro_first_row));
                this._buildChartData();
                this.CPIdata.push(this.euro_second_row);

                this.res_container = true;
                this.popup_counter++;
                this._openPopUp();
                this._addDataToHighchart();

            }

            if(this.is_mobile_device == true){
                setTimeout(() => {
                    let rect = document.getElementById("spainInflation-container").getBoundingClientRect();
                    window.scrollTo(rect.y, rect.y+600);
                }, 1000);
            }

        },

        _buildChartData(){

            var CPIvalues = this.AnnualCPI;
            var firstYear = parseInt(this.year_first_row);
            var secondYear = parseInt(this.year_second_row);

            var initial_year_CPI_value = CPIvalues[firstYear - 1999][0];
            var final_year_CPI_value = 0;
            var cumulativeInflation = 0;
            var adjustedValue = 0;

            for (var i = firstYear + 1; i <= secondYear; i++) {
                final_year_CPI_value = CPIvalues[i - 1999][0];
                
                cumulativeInflation = ((final_year_CPI_value - initial_year_CPI_value) / initial_year_CPI_value) * 100;
                
                adjustedValue = this.euro_first_row * (1 + (cumulativeInflation / 100));
                
                adjustedValue = Math.round((adjustedValue + Number.EPSILON) * 100) / 100;
                this.CPIdata.push(adjustedValue);
            }
        },

        _buildChartCategories(){

            var firstYear_cat = this.year_first_row;
            var secondYear_cat = this.year_second_row;

            for(var i = parseInt(firstYear_cat)+1; i < parseInt(secondYear_cat); i++){
                this.x_categories.push(i);
            }
        },

        _getAnnualCPI(){
            let success = response => {
                this.AnnualCPI = Object.values(response.data.dataSets[0].observations);
                this._addDataToHighchartSpainCPI();  
            }

            let url = 'https://sdmx.oecd.org/public/rest/data/OECD.SDD.TPS,DSD_PRICES@DF_PRICES_ALL,1.0/ESP.A.N.CPI.PA._T.N.GY?startPeriod=1999&endPeriod=2023&dimensionAtObservation=AllDimensions'

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('185', 'Error recuperando datos.');

            this._getAPICall(url, successHandler, failureHandler);
        },

        _getMonthlyCPI(){
            let success = response => {
                this.MonthlyCPI = Object.values(response.data.dataSets[0].observations);
                let fechaInicio = new Date(1999, 0, 1);
                let contadorMeses = 0;
                this.MonthlyCPI.forEach(element => {
                    this.arrayMonthlyCPI.push(element[0]);
                    let fechaConsecutiva = this._sumarMeses(fechaInicio, contadorMeses);
                    let mes = fechaConsecutiva.getMonth() + 1;
                    let anio = fechaConsecutiva.getFullYear();
                    let mesConsecutivo = `${('0' + mes).slice(-2)}-${anio}`;
                    this.arrayMonthlyName.push(mesConsecutivo);

                    contadorMeses++;
                })
                
            }

            let url = 'https://sdmx.oecd.org/public/rest/data/OECD.SDD.TPS,DSD_PRICES@DF_PRICES_ALL,1.0/ESP.M.N.CPI.PA._T.N.GY?startPeriod=1999-01&endPeriod=2023-12&dimensionAtObservation=AllDimensions';

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('186', 'Error recuperando datos.');

            this._getAPICall(url, successHandler, failureHandler);
            
        },

        _sumarMeses: function(fecha, meses) {
            let fechaNueva = new Date(fecha);
            fechaNueva.setMonth(fechaNueva.getMonth() + meses);
            return fechaNueva;
        },

        _isIframe(){
            var URLactual = window.location;
            if(URLactual.search == "?=iframe"){
                let sidebar = document.getElementById("sidebar-nav-container");
                sidebar.style.display = "none";
            }
        },

        _getInflationValues(){
            let success = response => {
                Object.values(response.data.dataSets[0].observations).forEach(element => {
                    this.inflationValues.push(element[0]);
                });
                this.inflationMonths = response.data.structure.dimensions.observation[0].values;
                this.inflationMonths.forEach(element => {
                    this.arrayInflationYears.push(element.name);
                });
            }

            let url = 'https://sdmx.oecd.org/public/rest/data/OECD.SDD.TPS,DSD_PRICES@DF_PRICES_ALL,1.0/ESP.A.N.CPI.PA._T.N.GY?startPeriod=1999&endPeriod=2023';

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('187', 'Error recuperando datos.');

            this._getAPICall(url, successHandler, failureHandler);
        },

        _addDataToHighchart: function(){
                
            let self = this;
            
            Vue.nextTick(function() {
                Highcharts.chart('highChart-content', {
                    title: {
                        text: 'Inflación anual en España'
                    },
                    subtitle: {
                        text: 'Gráfica de inflación en España'
                    },
                    yAxis: {
                        title: {
                            text: '<b>Cantidad [€]</b>'
                        }
                    },

                    xAxis: {
                        title: {
                            text: '<b>Tiempo [Años]</b>'
                        },
                        categories: self.x_categories
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle'
                    },

                    plotOptions: {
                        series: {
                            label: {
                                connectorAllowed: false
                            },
                        }
                    },

                    series: [{
                        name: 'Cantidad [€]',
                        data: self.CPIdata
                    }],
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }]
                    }
                });
            })
        },

        _addDataToHighchartSpainCPI: function(){

            let self = this;

            var combinedData = [];
            for (var i = 0; i < self.AnnualCPI.length; i++) {
                var year = 1999 + parseInt(i);
                var inflationRate = self.AnnualCPI[i][0];
                var color = inflationRate >= 0 ? '#FF0049' : '#00FF70';

                combinedData.push({
                    name: year.toString(),
                    y: inflationRate,
                    color: color
                });
            }

            Vue.nextTick(function(){
                Highcharts.chart('spainCPI_values', {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Inflación anual en España'
                    },
                    subtitle: {
                        text: 'Valores de inflación en España desde 1999 hasta 2023'
                    },
                    xAxis: {
                        title: {
                            text: '<b>Tiempo [Años]</b>',
                        },
                        categories: self.arrayInflationYears,
                        crosshair: true,
                    },
                    yAxis: {
                        min: -1,
                        title: {
                            text: 'Inflación [%]'
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0,
                            borderWidth: 0,
                            colorByPoint: true
                        }
                    },
                    series: [{
                        name: 'Inflación',
                        data: combinedData,
                    }]
                    
                });
            })
        },

        _addMonthlyDataToHighCharts: function(){

            let self = this;

            Vue.nextTick(function(){
                Highcharts.chart('MonthlyCPI_values', {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Inflación mensual en España'
                    },
                    subtitle: {
                        text: 'Valores de inflación en España desde 1999 hasta la actualidad'
                    },
                    xAxis: {
                        title: {
                            text: '<b>Tiempo [Meses]</b>'
                        },
                        categories: self.arrayMonthlyName,
                    },
                    yAxis: {
                        title: {
                            text: 'Inflación [%]'
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'Inflación',
                        data: self.arrayMonthlyCPI,
                    }],
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }]
                    }
                });
            })
        },

    },
}
</script>

<style scoped>
    #main-title h1 {
        color: var(--color-text-primary);
    }

    .main-container{
        margin-left: 30px;
        margin-top: 70px;
        overflow-y: scroll;
        background-color: var(--color-background);
    }

    h4, h2, p {
        color: var(--color-text-primary);
    }

    .input-style{
        width: 170px;
        border-color: grey;
    }
    .input-style-Quantity,
    .input-style-Period{
        width: 170px;
    }

    .inputs-container,
    .first-row-container,
    .third-row-container{
        display: inline-flex;
    }

    .input-res-container{
        margin-left: 20px;
    }

    .btn-calc-future,
    .btn-calc-past{
        width: 180px;
        height: 40px;
        background-color: #0faed7;
        color: white;
        font-weight: bold;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .btn-calc-spain{
        width: 180px;
        height: 40px;
        background-color: #0faed7;
        color: white;
        font-weight: bold;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        margin-top: 30px;
    }

    .btn-calc-future:active{
        background-color: #42B5E3;
    }

    .btn-calc-past:active{
        background-color: #42B5E3;
    }

    .btn-calc-spain:active{
        background-color: #42B5E3;
    }

    .highChart-content{
        margin-top: 10px;
    }

    .value-text-container span,
    .first-year-container span,
    .final-year-container span{
        font-size: 14px;
        margin-left: 10px;
    }

    .input-year-container{
        margin-left: 20px;
    }

    .res-container{
        text-align: center;
        background-color: #f2f2f2;
        margin-top: 50px;
        height: 100px;
    }

    .res-value{
        font-size: 20px;
    }

    .spainInflation-container{
        margin-top: 50px;
    }

    .futureInflation-container{
        margin-top: 80px;
    }

    .pastInflation-container{
        margin-top: 80px;
    }

    .input-values-container{
        display: inline-flex;
    }

    .value span,
    .average-inflation span,
    .period-text span{
        font-size: 14px;
        margin-left: 10px;
    }

    .input-2,
    .input-3{
        margin-left: 20px;
    }

    .future_res,
    .past_res{
        margin-left: 150px;
        text-align: center;
        background-color: #f2f2f2;
        width: 800px;
    }

    /*html{
        overflow: hidden;
    }*/

    .popup-modal{
        position: absolute;
        top: 30%;
        left: 30%;
        z-index: 1002;
        background-color: white;
        border-width: 1px;
        border-style: solid;
        border-color: black;
        padding: 10px;
        width: 700px;
        height: 450px;
        animation: fadeIn 1s;
        display: inline-flex;
    }

    .popup-style-container{
        position: fixed;
        height: 100vh;
        width: 100vw;
        z-index: 1001;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
    }

    @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
    }

    .btn-close{
        border: none;
        cursor: pointer;
        background-color: transparent;
    }

    .input-style-tlf{
        width: 200px;
        margin-top: 10px;
    }

    .input-style-email{
        width: 200px;
        margin-top: 10px;
    }

    .popup_tlf-container{
        width: 300px;
    }

    .popup_email-container{
        width: 300px;
        margin-top: 10px;
    }

    .popup-form-container{
        width: 400px;
        margin-top: 50px;
        margin-left: 100px;
    }

    .btn-close-container{
        margin-left: auto;
    }

    .btn-send{
        width: 180px;
        height: 40px;
        background-color: #0faed7;
        color: white;
        font-weight: bold;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        margin-top: 20px;
    }
    .btn-send:hover{
        background-color: #10B9E4;
    }
    .btn-send:active{
        margin-top: 21px;
    }

    @media screen and (min-width: 661px){
        .main-container{
            margin-top: 0px;
            margin-left: 0px;
        }

        .main-container > * {
            margin: 50px 5%;
        }
    }

    @media screen and (max-width: 660px){
        .main-container{
            margin-top: 0px;
            margin-left: 0px;
        }
        #ic-wrapper {
            margin: auto;
            width: 90%;
        }
        #main-title h1 {
            margin-top: 50px;
        }
        .first-row-container,
        .input-values-container{
            display: block;
        }
        .input-year-container{
            margin-top: 10px;
        }
        .input-year-container{
            margin-left: 0px;
        }
        .input-2,
        .input-3{
            margin-top: 10px;
            margin-left: 0px;
        }
        .inputs-container{
            display: block;
        }
        .future_res,
        .past_res{
            margin-left: 10px;
            width: 300px;
        }
        .popup-modal{
            top: 25%;
            left: 7%;
            width: 300px;
            height: 550px;
        }
        .popup-form-container{
            margin-left: 0px;
        }
        .text-container{
            width: 200px;
        }
        .btn-close-container{
            margin-left: -180px;
        }
    }

</style>